<template>
  <v-app :style="{ background: appBackground }">
    <v-main class="px-4">
      <slot />
    </v-main>
    <BaseFooter />
  </v-app>
</template>
<script>
import BaseFooter from "@/components/BaseFooter";
export default {
  components: {
    BaseFooter
  },
  computed: {
    appBackground() {
      let bg;
      if (this.$route.name === "access") {
        bg = "#121b19";
      } else if (this.$route.name === "login") {
        bg = `#0f1521`;
      }
      return bg;
    }
  }
};
</script>

<style lang="scss" scoped></style>
